const images_list_by_id = {
  2042088: {
    img_src: 'Business/Michel_Poucet.jpg',
    links: {
      Website: 'https://www.lmo.space',
    },
    podcast: 'Business',
  },
  2003427: {
    img_src: 'Business/Anne_Faber.jpg',
    links: {
      Website: 'https://anneskitchen.lu',
    },
    podcast: 'Business',
  },
  1952010: {
    img_src: 'Business/Vincent_Hein.jpg',
    links: {
      Website: 'https://www.fondation-idea.lu',
    },
    podcast: 'Business',
  },
  1899179: {
    img_src: 'Business/Yannick_Oswald.jpg',
    links: {
      "Yannick Oswald's blog": 'https://www.yannickoswald.com/',
      'Mangrove Capital': 'https://www.mangrove.vc',
    },
    podcast: 'Business',
  },
  1833363: {
    img_src: 'Business/Christophe_Timmermans.jpg',
    links: {
      'SolarCleano Website': 'https://solarcleano.com/en/',
    },
    podcast: 'Business',
  },
  1787418: {
    img_src: 'Business/Gerry_Wagner.jpg',
    links: {
      'House of Automobile': 'https://www.hoa.lu/',
      'The Chamber of Commerce’s A&T publication':
        'https://www.cc.lu/en/all-information/publications/detail/actualite-et-tendances-n28-voitures-electriques-au-luxembourg-maitriser-la-transition-face-aux-defis?tx_ccpublications_publications%5Bpage%5D=1&cHash=efcc92a278e9b20161b9394843071dde',
    },
    podcast: 'Business',
  },
  1525237: {
    img_src: 'Business/Antoine_Welter.jpg',
    links: {
      Website: 'https://www.circuli-ion.com/',
    },
    podcast: 'Business',
  },
  1459447: {
    img_src: 'Business/Antoine_Clasen.jpg',
    links: {
      Website: 'https://www.bernard-massard.lu/en/',
    },
    podcast: 'Business',
  },
  1222495: {
    img_src: 'Business/Florian_Feltes.jpg',
    links: {
      Website: 'https://zortify.com',
    },
    podcast: 'Business',
  },
  1709016: {
    img_src: 'Business/Mathias_Lentz.jpg',
    links: {
      Website: 'https://www.visitebrasserienationale.com',
    },
    podcast: 'Business',
  },
  1012344: {
    img_src: 'Business/Philippe_Hemard.jpg',
    links: {
      Website: 'https://www.clusterforlogistics.lu',
    },
    podcast: 'Business',
  },
  1140020: {
    img_src: 'Business/Pierre_Thein.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  1072770: {
    img_src: 'Business/Stavros_Evangelakakis.jpg',
    links: {
      Website: 'https://www.clusterforlogistics.lu',
    },
    podcast: 'Business',
  },
  1159953: {
    img_src: 'Business/Georges_Krombach.jpg',
    links: {
      Website: 'https://landewyck.com',
    },
    podcast: 'Business',
  },
  1195326: {
    img_src: 'Business/Martin_Schoonbroodt.jpg',
    links: {
      Website: 'https://www.probiotic-group.com/en/',
    },
    podcast: 'Business',
  },
  1279471: {
    img_src: 'Business/Barbara_Chevalier.jpg',
    links: {
      'cfl-mm.lu': 'https://www.cfl-mm.lu/fr-fr/organisation/cfl-multimodal',
      'clusterforlogistics.lu': 'https://www.clusterforlogistics.lu',
    },
    podcast: 'Business',
  },
  1320917: {
    img_src: 'Business/Yuriko_Backes.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  1380648: {
    img_src: 'Business/Dominic_Grieve.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  1432988: {
    img_src: 'Business/Daniel_Klemetz.jpg',
    links: {
      Website: 'https://www.greenworlder.com',
    },
    podcast: 'Business',
  },
  628077: {
    img_src: 'Business/Kim_Schumacher.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628078: {
    img_src: 'Business/Richard_Forson.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628080: {
    img_src: 'Business/Alex_Lawrence.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628081: {
    img_src: 'Business/John_Marshall.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628082: {
    img_src: 'Business/Christmas_Break.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628083: {
    img_src: 'Business/Nicolas_Mackel.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628084: {
    img_src: 'Business/Rene_Steinhaus.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628085: {
    img_src: 'Business/Jacques_Putz.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628085: {
    img_src: 'Business/Charlotte_Pedersen.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628087: {
    img_src: 'Business/Charles_Louis_Machuron.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628088: {
    img_src: 'Business/Marton_Fulop.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628089: {
    img_src: 'Business/Steve_Collar.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628089: {
    img_src: 'Business/Francoise_Thoma.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628091: {
    img_src: 'Business/Denise_Voss.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628092: {
    img_src: 'Business/Bert_Boerman.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628093: {
    img_src: 'Business/Mike_Reiffers.jpg',
    links: {
      Website: 'https://www.skeeled.com',
    },
    podcast: 'Business',
  },
  628094: {
    img_src: 'Business/Patrick_Kersten.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628095: {
    img_src: 'Business/Gerard_Hoffmann.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628096: {
    img_src: 'Business/Kim_Schumacher.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628097: {
    img_src: 'Business/Marie-Louise_Ashworth.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628098: {
    img_src: 'Business/Alexander_Tkachenko.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628099: {
    img_src: 'Business/Serge_Krancenblum.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628100: {
    img_src: 'Business/Georges_Berscheid.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628102: {
    img_src: 'Business/Kevin_Muller.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628103: {
    img_src: 'Business/Oliver_Schimek.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628104: {
    img_src: 'Business/Christmas_Break.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628105: {
    img_src: 'Business/Pierre_Thein.jpg',
    links: {
      Website: 'http://www.heingroup.lu/index.php/de/',
    },
    podcast: 'Business',
  },
  628106: {
    img_src: 'Business/Stephane_Pesch.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628107: {
    img_src: 'Business/Joe_Hoffmann.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628108: {
    img_src: 'Business/Jacques_Putz.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628109: {
    img_src: 'Business/Luc_Provost.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628110: {
    img_src: 'Business/Steve_Collar.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628111: {
    img_src: 'Business/Alberto_Dalmasso.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628113: {
    img_src: 'Business/Enrique_Sacau.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628114: {
    img_src: 'Business/Malik_Zeniti.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628115: {
    img_src: 'Business/Fernand_Ernster.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628116: {
    img_src: 'Business/Rolf_Sorg.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628117: {
    img_src: 'Business/Franz_Fayot.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628118: {
    img_src: 'Business/Franz_Fayot.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628119: {
    img_src: 'Business/Happy_Summer_Break.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  628120: {
    img_src: 'Business/Mike_Reiffers.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  629984: {
    img_src: 'Business/Marie-Louise_Ashworth.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  642905: {
    img_src: 'Business/Pascale_Junker.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  642905: {
    img_src: 'Business/Kim_Schumacher.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  667096: {
    img_src: 'Business/Jean-Claude_Juncker.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  698312: {
    img_src: 'Business/David_Gray.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  724278: {
    img_src: 'Business/Gerard_Hoffmann.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  750152: {
    img_src: 'Business/Happy_Summer_Break.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  761589: {
    img_src: 'Business/Christmas_Break.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  771566: {
    img_src: 'Business/Enrique_Sacau.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  783919: {
    img_src: 'Business/Francois_Pauly.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  797484: {
    img_src: 'Business/Pascal_Bouvry-Roger_Lampach.jpg',
    links: {
      Website: 'https://www.luxprovide.lu',
    },
    podcast: 'Business',
  },
  810997: {
    img_src: 'Business/Charles_Louis_Machuron.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  824597: {
    img_src: 'Business/Olaf_Kordes.jpg',
    links: {
      Website: 'https://www.luxempart.lu',
    },
    podcast: 'Business',
  },
  836521: {
    img_src: 'Business/Tom_Michels.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  859365: {
    img_src: 'Business/Bob_Kneip.jpg',
    links: {
      Website: 'https://www.kneip.com',
    },
    podcast: 'Business',
  },
  867283: {
    img_src: 'Business/Filip_Westerlund.jpg',
    links: {
      Website: 'https://www.ourchoicefashion.com',
    },
    podcast: 'Business',
  },
  880287: {
    img_src: 'Business/Fedor_Antonov.jpg',
    links: {
      Website: 'https://anisoprint.com',
    },
    podcast: 'Business',
  },
  903317: {
    img_src: 'Business/Mike_Reiffers.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Business',
  },
  930963: {
    img_src: 'Business/Kevin_P_Nash.jpg',
    links: {
      Website: 'https://www.clusterforlogistics.lu',
    },
    podcast: 'Business',
  },

  // Zero Net Future

  890408: {
    img_src: 'Zero_net_future/Claude_Turmes.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Zero net future',
  },
  736731: {
    img_src: 'Zero_net_future/Claudia_Hitaj.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Zero net future',
  },
  1028457: {
    img_src: 'Zero_net_future/Georges_Bock.jpg',
    links: {
      Website: 'https://en.moniflo.com',
    },
    podcast: 'Zero net future',
  },
  934104: {
    img_src: 'Zero_net_future/Selma_Vincent.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Zero net future',
  },
  959609: {
    img_src: 'Zero_net_future/Christa_Anderson.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Zero net future',
  },

  // Dark Matter Uncovered

  628079: {
    img_src: 'Space/Marc_Serres.jpg',
    links: {
      'Space Agency': 'https://space-agency.public.lu/en.html',
      'hoganlovells.com': 'hhttps://www.hoganlovells.com/en',
    },
    podcast: 'Dark Matter Uncovered',
  },
  816768: {
    img_src: 'Space/Marc_Serres.jpg',
    links: {
      // 'Website': 'https://youtube.com',
    },
    podcast: 'Dark Matter Uncovered',
  },
  628112: {
    img_src: 'Space/Rachel_Kent-Pierre_Reuter.jpg',
    links: {
      Website: 'https://www.hoganlovells.com/en',
    },
    podcast: 'Dark Matter Uncovered',
  },
  1368046: {
    img_src: 'Space/Pierre_Reuter-George_V_John.jpg',
    links: {
      Website: 'https://www.hoganlovells.com/en',
    },
    podcast: 'Dark Matter Uncovered',
  },
  1509249: {
    img_src: 'Space/Pierre_Reuter-George_V_John.jpg',
    links: {
      Website: 'https://www.hoganlovells.com/en',
    },
    podcast: 'Dark Matter Uncovered',
  },
  941466: {
    img_src: 'Space/Emmanuel_Rammos.jpg',
    links: {
      'skyflox.eu': 'https://skyflox.eu',
      'hoganlovells.com': 'https://www.hoganlovells.com/en',
    },
    podcast: 'Dark Matter Uncovered',
  },
  909140: {
    img_src: 'Space/Edgar_Milic.jpg',
    links: {
      'luxspace.lu': 'https://luxspace.lu',
      'hoganlovells.com': 'https://www.hoganlovells.com/en',
    },
    podcast: 'Dark Matter Uncovered',
  },
  1037429: {
    img_src: 'Space/Kathryn_Hadler.jpg',
    links: {
      'esric.lu': 'https://www.esric.lu',
      'hoganlovells.com': 'https://www.hoganlovells.com/en',
    },
    podcast: 'Dark Matter Uncovered',
  },
  1081580: {
    img_src: 'Space/Mahulena_Hofmann.jpg',
    links: {
      'coolchain.org': 'https://coolchain.org',
      'hoganlovells.com': 'https://www.hoganlovells.com/en',
    },
    podcast: 'Dark Matter Uncovered',
  },
  844420: {
    img_src: 'Space/Jaroslaw-Jaworski.jpg',
    links: {
      'redwirespace.com': 'https://redwirespace.com',
      'hoganlovells.com': 'https://www.hoganlovells.com/en',
    },
    podcast: 'Dark Matter Uncovered',
  },
  877217: {
    img_src: 'Space/Alex_Pospekhov-Artem_Axelrod.jpg',
    links: {
      'mission.space': 'https://www.mission.space',
      'hoganlovells.com': 'https://www.hoganlovells.com/en',
    },
    podcast: 'Dark Matter Uncovered',
  },
}

export { images_list_by_id }
