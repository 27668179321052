import H3Title from '../../components/H3Tittle/H3Title'
import ChamberOfCommerceLogo from '../../components/_SVG/ChamberOfCommerce'
import HoganLovellsLogo from '../../components/_SVG/HoganLovellsLogo'
import './Partners.css'

const Partners = ({
  isHomeColor,
  colorSecondary = '',
  title = 'Our Partners',
  logoColor = 'var(--grey-home)',
}) => {
  return (
    <div className="container position-relative">
      <div className="row">
        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
          <H3Title isHomeColor={isHomeColor} colorSecondary={colorSecondary} text={title} />
        </div>
        <div className="d-flex logo-wrapper align-items-center col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
          <ChamberOfCommerceLogo className="my-3" color={logoColor} width={260} />
        </div>
        <div className="d-flex logo-wrapper align-items-center col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
          <HoganLovellsLogo className="my-3" color={logoColor} width={200} />
        </div>
      </div>
    </div>
  )
}

export default Partners
